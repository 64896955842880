import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/layouts/header';
import { Theme, createStyles, makeStyles, Grid, Typography } from '@material-ui/core';
import { FiberManualRecord, TrendingFlat } from '@material-ui/icons';
import { team } from '../utils/constants';
import Member from '../components/about/member';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        about: {
            position: 'relative',
            marginTop: theme.spacing(15)
        },
        map: {
            width: '100%',
            maxWidth: 500,
            display: 'none',
            position: 'absolute',
            top: -150,
            right: 0,
            [theme.breakpoints.up('md')]: {
                display: 'block'
            },
            [theme.breakpoints.up('lg')]: {
                top: -200,
                maxWidth: 600
            },
            [theme.breakpoints.up(1400)]: {
                top: -120
            }
        },
        dot: {
            position: 'absolute',
            top: 95,
            left: 215,
            width: 15,
            height: 15,
            borderRadius: '100%',
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.up('lg')]: {
                top: 110,
                left: 255
            }
        },
        curvedArrow: {
            position: 'absolute',
            top: -145,
            left: 100,
            color: theme.palette.primary.main,
            transform: 'rotate(-45deg)',
            [theme.breakpoints.up('lg')]: {
                top: -95,
                left: 150
            }
        },
        line: {
            border: `2px solid ${theme.palette.primary.main}`,
            borderColor: `transparent transparent transparent ${theme.palette.primary.main}`,
            height: 275,
            width: 200,
            borderRadius: '160px 0 0 160px',
            [theme.breakpoints.up('lg')]: {
                height: 220
            }
        },
        title: {
            '& span': {
                color: theme.palette.primary.main
            }
        },
        info: {
            marginTop: theme.spacing(2),
            maxWidth: 715
        },
        team: {
            marginTop: theme.spacing(5)
        },
        members: {
            marginTop: theme.spacing(2)
        }
    })
);

const About = () => {
    const classes = useStyles();

    return (
        <Layout page='Nosotros'>
            <SEO title='Nosotros' />

            <Header siteTitle='Nacidos en Villahermosa, Tabasco' />

            <main>
                <Grid container direction='column' className={classes.about}>
                    <div className={classes.map}>
                        <img src={require('../assets/images/about/tabasco-mapa.svg')} />
                        <div className={classes.dot}></div>
                        <div className={classes.curvedArrow}>
                            <div className={classes.line}></div>
                        </div>
                    </div>
                    <Grid item>
                        <Typography variant='h2' className={classes.title}>
                            Sobre <span>nodeaxan</span>
                        </Typography>
                    </Grid>
                    <Grid item container direction='column' spacing={2} className={classes.info}>
                        <Grid item>
                            <Typography variant='body1'>
                                Crear. Esa es la palabra. Adoramos crear cosas, y somos incapaces de dejar de hacerlo. La tecnología de hoy en día nos permite crear prácticamente de la nada, utilizando solamente nuestra lógica e imaginación.
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant='body1'>
                                Después de mucho tiempo de crear para otras personas, decidimos crear para nosotros mismos, y los clientes que, como usted, entienden el valor de lo intangible. Sabemos que la tecnología es una inversión que vale la pena, y que la rentabilidad de sistemas informáticos es enorme y de por vida.
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant='body1'>
                                Es por eso que, desde el corazón de Villahermosa, ofrecemos servicios tecnológicos en toda la república mexicana. Nos inspira la naturaleza de las necesidades humanas, y queremos transmitir nuestra empatía a través de una imagen profesional y amigable.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <div className={classes.team}>
                    <Typography variant='h2'>Equipo</Typography>

                    <Grid container spacing={4} className={classes.members}>
                        {team.map((member, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                <Member member={member} />
                            </Grid>
                        ))}
                    </Grid>
                </div> */}
            </main>
        </Layout>
    );
};

export default About;